import { createStore, StoreOptions } from 'vuex';
import { resizeModule } from '#root/src/store/vuex-store/modules/resizeModule';
import {
  indexActions,
  indexGetters,
  indexMutations,
  indexState,
} from '#root/src/store/vuex-store/modules/indexModule';
import VuexPersistence from 'vuex-persist';
import { cabinetModule } from '@/store/vuex-store/modules/cabinetModule';
import { footerModule } from '@/store/vuex-store/modules/footerModule';
import { keycloakModule } from '@/store/vuex-store/modules/keycloakModule';
import OrderCreate from '@/store/order-create';
import { browserModule } from '@/store/browser';
import { contractModule } from '@/store/contract';
import { passportFormModule } from '@/store/passport-form';
import { loyaltyModule } from '@/store/vuex-store/modules/loyaltyModule';
import { trackingModule } from './modules/trackingModule';
import MainState from '../../types/store';
import { loaderModule } from './modules/loaderModule';
import { flomniModule } from './modules/flomniModule';
import cookieAlertModule from '../cookie-alert';
import { authModule } from './modules/authModule';

function createVuexStore(runsOnClient: boolean) {
  if (!runsOnClient && global.cdekStore) {
    return global.cdekStore;
  }

  const defaultStoreData: StoreOptions<MainState> = {
    state: indexState,
    actions: indexActions,
    mutations: indexMutations,
    getters: indexGetters,
    modules: {
      loader: loaderModule,
      resize: resizeModule,
      cabinet: cabinetModule,
      footer: footerModule,
      flomni: flomniModule,
      keycloak: keycloakModule,
      'order-create': OrderCreate,
      'cookie-alert': cookieAlertModule,
      tracking: trackingModule,
      browser: browserModule,
      contract: contractModule,
      'passport-form': passportFormModule,
      loyalty: loyaltyModule,
      auth: authModule,
    },
  };

  if (runsOnClient) {
    const vuexLocal = new VuexPersistence<MainState>({
      storage: window.localStorage,
      modules: ['keycloak'],
    });

    defaultStoreData.plugins = [vuexLocal.plugin];
  }
  const vuexStore = createStore<MainState>(defaultStoreData);

  if (!runsOnClient) {
    global.cdekStore = vuexStore;
  }

  return vuexStore;
}

export { createVuexStore };
