import { cdekConsoleError } from '#root/src/utils/console-wrapper';
import { getItemLS, setItemLS } from '@/utils/local-storage.utils';

const CITY_KEY = 'localstorage:city_key';

export function getLocalCity() {
  try {
    const cityString = getItemLS(CITY_KEY) || null;

    return cityString ? JSON.parse(cityString) : null;
  } catch (error) {
    cdekConsoleError(error);
    return null;
  }
}

export function saveLocalCity(city: unknown) {
  try {
    setItemLS(CITY_KEY, JSON.stringify(city));
  } catch (error) {
    cdekConsoleError(error);
  }
}
