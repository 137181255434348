import { ResizeState } from '#root/src/types/store/resize';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import MainState from '#root/src/types/store';

const TABLE = 640;
const DESKTOP = 1000;
const DESKTOP_LARGE = 1280;

const defaultState = (): ResizeState => ({
  width: 0,
  height: 0,
  screen: {
    mobile: false, // 320px
    table: false, // >=640
    desktop: false, // >=1000
    desktopLarge: false, // >=1280
  },
});

export const mutations: MutationTree<ResizeState> = {
  setWidth(store, payload) {
    store.width = payload.width;
  },
  setScreen(store, payload) {
    store.screen = payload.screen;
  },
};

const actions: ActionTree<ResizeState, MainState> = {
  setWidth({ commit }, { clientWidth }) {
    commit('setWidth', {
      width: clientWidth,
    });
    const screen = {
      mobile: clientWidth <= TABLE, // <= 640px
      table: clientWidth > TABLE && clientWidth <= DESKTOP, // >640 && <=1000
      desktop: clientWidth >= DESKTOP, // >1000
      desktopLarge: clientWidth >= DESKTOP_LARGE, // >1280
    };
    commit('setScreen', {
      screen,
    });
  },
  setScreenByType({ commit }, type) {
    const screen = {
      mobile: type === 'mobile', // <= 640px
      table: type === 'tablet', // >640 && <=1000
      desktop: type === 'desktop', // >1000
      desktopLarge: type === 'desktopLarge', // >1280
    };

    commit('setScreen', {
      screen,
    });
  },
};

const getters: GetterTree<ResizeState, MainState> = {
  screen: ({ screen }) => screen,
  isDesktop: ({ screen }) => screen.desktop,
};

export const resizeModule: Module<ResizeState, MainState> = {
  namespaced: true,
  getters,
  mutations,
  state: defaultState,
  actions,
};
