import { CONTRACT_TYPE } from '../middleware/mutation-types';

export const state = () => ({
  contract: {
    corporate: {},
    private: {},
    ip: {},
  },
});

export const mutations = {
  [CONTRACT_TYPE.UPDATE_FORM](store, { form, type }) {
    store.contract[type] = { ...form };
  },
};

export const actions = {
  async [CONTRACT_TYPE.UPDATE_FORM]({ commit }, { form, type }) {
    commit(CONTRACT_TYPE.UPDATE_FORM, { form, type });
  },
};

export const getters = {
  corporateForm({ contract }) {
    return contract.corporate;
  },
  privateForm({ contract }) {
    return contract.private;
  },
  ip({ contract }) {
    return contract.ip;
  },
};

export const contractModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
