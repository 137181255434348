import type { App } from 'vue';
import GraphqlApiClient from '#root/src/api-clients/graphql-api-client';

export default {
  install: (app: App, runsOnClient = false, url = import.meta.env.VITE_HTTP_ENDPOINT) => {
    let client: GraphqlApiClient;

    if (!runsOnClient) {
      if (global.cdekGraphqlApiClient) {
        client = global.cdekGraphqlApiClient;
      } else {
        client = new GraphqlApiClient(url);
        global.cdekGraphqlApiClient = client;
      }
    } else {
      client = new GraphqlApiClient(url);
    }

    // У нас большая часть компонентов будет на options api и там где есть аксиос
    // мы будем использовать его как  this.$restApi.
    app.config.globalProperties.$graphqlApi = client;

    // Что-то новое будем писать и использованием composition api
    // при таком подходе мы не имеем доступа к this.
    // Чтобы использовать там клиент, будем делать inject('restApi')
    app.provide('graphqlApi', client);
  },
};
