export function initDefaultGtmPlugin(id: string) {
  const script = document.createElement('script');
  script.text = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
     `;

  document.head.appendChild(script);
}

export function initSecondGtm(id: string) {
  const firstScript = document.createElement('script');
  firstScript.async = true;
  firstScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  document.head.appendChild(firstScript);

  const secondScript = document.createElement('script');

  secondScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}');
  `;
  document.head.appendChild(secondScript);
}

export function initGtmPlugin() {
  const gtmId = import.meta.env.VITE_GTM_ID;

  if (gtmId) {
    initDefaultGtmPlugin(gtmId);
  }

  const gtmIdSecond = import.meta.env.VITE_GTM_ID_SECOND;

  if (gtmIdSecond) {
    initSecondGtm(gtmIdSecond);
  }
}
