import client from '@/api-clients/cabinetApi/client';
import { App } from 'vue';
import { CdekStore } from '@/types/store';

export default {
  install(app: App, store: CdekStore, url = import.meta.env.VITE_HTTP_ENDPOINT) {
    // Пока не понимаю разницу между этими двумя значениеям
    // Но в сср у нас нет доступа к виндов
    // const apiHost = `${window.location.protocol}//${window.location.host}`;
    const apiPrefix = '/api-lkfl';
    const apiClient = client(url, apiPrefix, store);
    app.config.globalProperties.$apiClient = apiClient;
    store.$apiClient = apiClient;
    app.provide('apiClient', apiClient);
  },
};
