import { cdekConsoleError } from '#root/src/utils/console-wrapper';

export const state = () => ({
  browser: null,
});

export const mutations = {
  update(store, payload) {
    store.browser = payload.browser;
  },
};

export const actions = {
  async update({ commit }, { userAgent }) {
    try {
      const module = await import('bowser');

      commit('update', {
        browser: module.default.getParser(userAgent),
      });
    } catch (err) {
      cdekConsoleError(err);
    }
  },
};

export const getters = {
  browser: ({ browser }) => browser,
};

export const browserModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
