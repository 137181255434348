import { MutationTree } from 'vuex';
import { CreateOrderErrorAlerts, CreateOrderState } from '@/types/store/createOrder';
import initialState from './state';

const createOrderMutations: MutationTree<CreateOrderState> = {
  setDefaultState(state) {
    const newState = initialState();
    Object.keys(newState).forEach((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[k] = newState[k];
    });
  },
  setIsRequestDirection(state, payload) {
    state.isRequestDirection = !!payload;
  },
  setIsRequestTariff(state, payload) {
    state.isRequestTariff = !!payload;
  },
  setFrom(state, payload) {
    state.direction.from = 'fullName' in (payload ?? {}) ? payload : null;
  },
  setTo(state, payload) {
    state.direction.to = 'fullName' in (payload ?? {}) ? payload : null;
  },
  setSize(state, payload) {
    if (payload) {
      payload.dimension.height = Number(payload.dimension.height);
      payload.dimension.width = Number(payload.dimension.width);
      payload.dimension.length = Number(payload.dimension.length);
      payload.maxWeight = Number(payload.maxWeight);
      state.direction.size = payload;
    } else {
      state.direction.size = null;
    }
  },
  setSizeOptions(state, payload) {
    state.sizeOptions = payload;
  },
  setCurrencies(state, payload) {
    state.currencies = payload;
  },
  setPayerCurrencies(state, payload) {
    state.payerCurrencies = payload;
  },
  setActiveCurrency(state, payload) {
    state.activeCurrency = payload;
  },
  setTabs(state, payload) {
    state.tabs = payload;
  },
  setCurrentTab(state, payload) {
    state.currentTab = payload;
  },
  setCurrentTariff(state, payload) {
    state.currentTariff = payload;
  },
  setTabForm(state, payload) {
    state.tabForm = payload;
  },
  setCost(state, { totalCost, deliveryPrice }) {
    state.totalCost = totalCost;

    if (deliveryPrice) {
      state.deliveryPrice = deliveryPrice;
    }
  },
  setLoyalty(state, payload) {
    state.loyalty = payload;
  },
  replaceState(state, newState) {
    Object.keys(newState).forEach((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[k] = newState[k];
    });
  },
  setIsLoadTariffInfoError(state, payload = true) {
    state.isLoadTariffInfoError = payload;
  },
  setIsDirectionError(state, payload = true) {
    state.isDirectionError = payload;
  },
  setErrorAlerts(state, payload: CreateOrderErrorAlerts[]) {
    state.errorAlerts = payload;
  },
};

export default createOrderMutations;
