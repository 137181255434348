import axios, { CreateAxiosDefaults } from 'axios';
import https from 'https';
import IPage from '@/types/dto/page';

const GET_FAQ = '/website/faq';
const ENDPOINT_WEBSITE_PAGE = '/website/page';

interface IWebsitePageReqParams {
  url: string;
  websiteId: string;
  locale: string;
}
class PmsApiClient {
  _axiosAuthInstance;

  constructor(apiHost: string) {
    if (!apiHost) {
      throw new Error('"apiHost" {string} param is required.');
    }

    const apiPrefix = '/api-pms';

    const baseURL = `${apiHost}/${apiPrefix.replace(/\/+/, '')}`;

    const axiosConfig: CreateAxiosDefaults = { baseURL };

    if (typeof window === 'undefined') {
      axiosConfig.httpsAgent = new https.Agent({
        rejectUnauthorized: false,
      });
    }

    this._axiosAuthInstance = axios.create(axiosConfig);
  }

  // Получение списка FAQ
  async getFaq(category?: string, isoLocale?: string) {
    const params = {
      category,
      isoLocale,
    };
    const res = await this._axiosAuthInstance.get(GET_FAQ, { params });

    return res.data.data;
  }

  async websitePage(params: IWebsitePageReqParams): Promise<IPage> {
    const res = await this._axiosAuthInstance.get(ENDPOINT_WEBSITE_PAGE, { params });

    return res.data.data.page;
  }
}

export default PmsApiClient;
