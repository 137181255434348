import { TrackingState } from '@/types/store/tracking';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import MainState from '@/types/store';

const initialTracking = (): TrackingState => ({
  autoSubmit: false,
  trackId: null,
  phone: null,
  tracking: null,
  token: null,
  loading: false,
  displayCaptchaSearch: false,
  displayCaptchaChange: false,
  displayCaptchaPhone: false,
});

export const state = (): TrackingState => ({
  ...initialTracking(),
});

export const mutations: MutationTree<TrackingState> = {
  updateTrackId(state, payload) {
    state.trackId = payload.trackId;
  },
  updateToken(state, payload) {
    state.token = payload.token;
  },
  updateDisplayCaptchaSearch(state, payload) {
    state.displayCaptchaSearch = payload.displayCaptchaSearch;
  },
  updateDisplayCaptchaChange(state, payload) {
    state.displayCaptchaChange = payload.displayCaptchaChange;
  },
  updateDisplayCaptchaPhone(state, payload) {
    state.displayCaptchaPhone = payload.displayCaptchaPhone;
  },
  updateAutoSubmit(state, payload) {
    state.autoSubmit = payload.autoSubmit;
  },
  updatePhone(state, payload) {
    state.phone = payload.phone;
  },
  updateLoading(state, payload) {
    state.loading = payload.loading;
  },
  recordTrackingData(state, payload) {
    state.tracking = payload.tracking;
  },
  clear(state, payload: TrackingState) {
    for (const property in payload) {
      if (payload.hasOwnProperty(property)) {
        (state as any)[property] = payload[property as keyof TrackingState];
      }
    }
  },
  reset(state, payload) {
    state.phone = payload.phone;
    state.tracking = payload.tracking;
    state.loading = payload.loading;
  },
};

export const actions: ActionTree<TrackingState, MainState> = {
  clearTracking({ commit }) {
    commit('clear', {
      ...initialTracking(),
    });
  },
  reset({ commit }) {
    commit('reset', {
      phone: null,
      tracking: null,
      loading: false,
    });
  },
  setTracking({ commit }, payload) {
    commit('recordTrackingData', payload);
    commit('updateLoading', { loading: true });
  },
  setTrackIdAndSubmit({ commit }, payload) {
    commit('updateTrackId', {
      trackId: payload.trackId,
    });
    commit('updateAutoSubmit', {
      autoSubmit: payload.autoSubmit,
    });
  },
};

export const getters: GetterTree<TrackingState, MainState> = {
  trackId({ trackId }) {
    return trackId;
  },
  autoSubmit({ autoSubmit }) {
    return autoSubmit;
  },
  phone({ phone }) {
    return phone;
  },
  tracking({ tracking }) {
    return tracking;
  },
  loading({ loading }) {
    return loading;
  },
  token({ token }) {
    return token;
  },
  displayCaptchaSearch({ displayCaptchaSearch }) {
    return displayCaptchaSearch;
  },
  displayCaptchaChange({ displayCaptchaChange }) {
    return displayCaptchaChange;
  },
  displayCaptchaPhone({ displayCaptchaPhone }) {
    return displayCaptchaPhone;
  },
};

export const trackingModule: Module<TrackingState, MainState> = {
  namespaced: true,
  getters,
  actions,
  state,
  mutations,
};
