import BaseApiClient from '#root/src/api-clients/base-api-client';
import { DefaultRequestData } from '#root/src/types/dto/defaultRequestData';
import { SiteParam } from '#root/src/types/dto/siteParams';
import IMenuItem from '#root/src/types/dto/menuItem';
import { FooterData, FooterRequestData } from '#root/src/types/store/footer';
import { getOfficeUrl } from '#root/src/utils/offices.request.utils';
import { CdekOfficeFilter, OfficeRequestData } from '#root/src/types/dto/cdek-office';
import { News } from '#root/src/types/dto/news';
import IntegrationQuestion from '#root/src/types/dto/integrationQuestions';
import {
  IntegrationModuleResponse,
  IntegrationShortModule,
} from '#root/src/types/dto/integrationModule';

import {
  BaseCreationRequest,
  ConclusionRequest,
  ContragentInformation,
  ExistsCreationRequest,
  FnsCard,
  JoinCompanyRequest,
  SelfcareResponse,
} from '#root/src/types/dto/fns-card';
import { CityAutocomplete } from '#root/src/types/dto/city-autocomplete';
import { removeSideSlashes } from '#root/src/utils/string.utils';
import { CdekCityRequest, CdekFullCity } from '@/types/dto/city';
import {
  RequestWebsiteComponentAlert,
  ResponseWebsiteComponentAlert,
  WebsiteAlert,
} from '@/types/dto/alert';
import { IWebsiteFaqData } from '@/types/dto/websiteFaq';
import { IWebsitePageData } from '@/types/dto/restWebsitePage';
import { IPaymentVerifyParams, IPaymentVerifyResponse } from '@/types/dto/paymentVerify';
import { AxiosRequestConfig } from 'axios';
import { RedisError } from '#root/src/utils/redis-storage/RedisError';
import type {
  ICalculateRequest,
  ICalculateResponse,
  IServicesRequest,
  IServicesResponse,
} from '#root/src/types/dto/calculatorB2B';
import { AutodetectCityResponse } from '@/types/dto/autodetect-city';
import { OfficeMapDto } from '@/types/dto/office';

const WEBSITE_COUNTRY = '/website/country/get/?locale={locale}';
const WEBSITE_WORKLOAD = '/website/workload/?officeCode={officeCode}';
const WEBSITE_MENU = '/website/menu/?locale={locale}';
const WEBSITE_INTEGRATION_MODULE = '/website/integration/module/';
const WEBSITE_INTEGRATION_MODULE_BY_ID = '/website/integration/module/{id}/';
const WEBSITE_QUESTION_BY_GROUP_TAG = '/website/question/?groupTag={tag}';
const WEBSITE_NOTIFICATION_SEND_JIRA = '/website/notification/send-jira/';
const WEBSITE_NOTIFICATION_SEND_JIRA_OI = '/website/notification/send-jira-oi/';
const WEBSITE_PARAM = '/website/param/';
const WEBSITE_FOOTER = '/website/footer/';
const WEBSITE_ALERT = '/website/alert/';
const WEBSITE_COMPONENT_ALERT = '/website/component/alert/';
const HEALTHCHECK_NETWORK = '/healthcheck/network/';
const WEBSITE_HELP_WIDGET = '/website/help-widget/';
const OTHER_CDEK_SHOPPING_FEATURED_PRODUCTS =
  '/other/cdek-shopping/featured-products/?locale={locale}';
const OTHER_CDEK_SHOPPING_FEATURED_PRODUCTS_CATEGORY =
  '/other/cdek-shopping/featured-products-category/?locale={locale}';
const WEBSITE_OFFICE_MAP = '/website/office/map/';
const WEBSITE_OFFICE = '/website/office/';
const WEBSITE_CITY_DETECT = '/website/city/detect/?locale={locale}';
const WEBSITE_CITY_BY_COORDS =
  '/website/city/by-coords/?locale={locale}&latitude={latitude}&longitude={longitude}';
const WEBSITE_CITY = '/website/city/?locale={locale}';

const WEBSITE_NEWS = '/website/news/?locale={locale}&sort={sort}&limit={limit}&page={page}';
const WEBSITE_CITY_AUTOCOMPLETE = '/website/city/auto-complete/?name={name}&locale={locale}';
const WEBSITE_VIDEO_STREAM_OFFICE = '/website/video-stream/office/?id={id}&locale={locale}';
const WEBSITE_DOGOVOR_CREATE_INDIVIDUAL = '/website/dogovor/create-individual/';
const WEBSITE_DOGOVOR_CREATE_BUSINESS = '/website/dogovor/create-business/';
const WEBSITE_DOGOVOR_CREATE_IP = '/website/dogovor/create-ip/';
const WEBSITE_DOGOVOR_BIK = '/website/dogovor/bik-info/?bik={bik}&locale={locale}';
const WEBSITE_DOGOVOR_INN = '/website/dogovor/inn-info/?inn={inn}&locale={locale}';
const WEBSITE_DOGOVOR_CREATE_BUSINESS_FORM = '/website/dogovor/create-business-form';
const WEBSITE_IDENTITY_PARAMS = '/website/identity/params/';
const WEBSITE_IDENTITY_PASSPORT =
  '/website/identity/{citizenship}/passport/?operationId={operationId}';
const WEBSITE_IDENTITY_ID_CARD =
  '/website/identity/{citizenship}/identity-card/?operationId={operationId}';
const WEBSITE_IDENTITY_OTHER_DOCUMENT =
  '/website/identity/other/undefined/?operationId={operationId}';
const WEBSITE_SITEMAP = `/website/sitemap/?locale={locale}`;
const WEBSITE_FREEZED_LOYALTY_POINTS = '/payment/customer/freezed-points/?customerUuid={user_uuid}';
const WEBSITE_RATING_PRODUCT_ADD = '/website/rating-product/add/';
const WEBSITE_ORDER_CHECK = '/website/order/check/?order={order}';
const WEBSITE_NOTIFICATION_PACKAGE_ORDER = '/website/notification/package-order/';
const WEBSITE_IDENTITY_CDEK_ID =
  '/website/identity/{citizenship}/cdek-id/?operationId={operationId}';

const WEBSITE_AGREEMENT_ONLINE_FNS_CARD =
  '/website/agreement-online/fns-card/?inn={inn}&locale={locale}';
const WEBSITE_AGREEMENT_ONLINE_JOIN = '/website/agreement-online/join/?locale={locale}';
const WEBSITE_AGREEMENT_ONLINE_CONCLUSION =
  '/website/agreement-online/conclusion/?locale={locale}&inn={inn}&cityUuid={cityUuid}';
const WEBSITE_AGREEMENT_ONLINE_SELFCARE =
  '/website/agreement-online/selfcare-users/?locale={locale}';

const WEBSITE_AGREEMENT_ONLINE_BY_REQUISTIES = `/website/agreement-online/by-requisites/?inn={inn}`;
const WEBSITE_AGREEMENT_ONLINE_CREATE_NEW =
  '/website/agreement-online/contract-for-new-contragent/?locale={locale}';
const WEBSITE_AGREEMENT_ONLINE_CREATE_EXIST =
  '/website/agreement-online/contract-for-exist-contragent/?locale={locale}';

const WEBSITE_AGREEMENT_ONLINE_CONTRACT = '/website/agreement-online/contract/?locale={locale}';

const WEBSITE_FAQ = '/website/faq/?locale={locale}&withFooter={withFooter}';
const WEBSITE_PAGE = '/website/page/?url={url}&locale={locale}';

const WEBSITE_PAYMENT_AUTH_CODE_VERIFY = '/payment/cash-on-delivery/auth-code-verify';
const OTHER_YANDEX_GEO_SUGGEST = `/other/yandex-geo-suggest/?text={text}&locale={locale}`;

const CALCULATOR_SERVICES = '/website/calculator/services/';
const CALCULATOR_CALCULATE = '/website/calculator/calculate/';

const CUSTOMER_GET_LINK = '/payment/customer/get-link/?locale={locale}&number={number}&id={id}';

export default class RestApiClient extends BaseApiClient {
  constructor(apiHost: string, prefix = 'api-site') {
    super(apiHost, prefix);
  }

  async unAuthCachedGet(url: string, config: AxiosRequestConfig = {}) {
    try {
      config.cache = {
        methods: ['get'],
      };
      return await this.unAuthAxios().get(this.endpointUrl(url), config);
    } catch (e) {
      if (e instanceof RedisError) {
        config.cache = false;

        return await this.unAuthAxios().get(this.endpointUrl(url), config);
      }

      throw e;
    }
  }

  async websiteMenu(locale = 'ru'): Promise<IMenuItem[]> {
    const url = WEBSITE_MENU.replace('{locale}', locale);

    const res = await this.unAuthCachedGet(url);
    return res.data.data;
  }

  async websiteParam({ locale }: DefaultRequestData): Promise<SiteParam[]> {
    const url = `${WEBSITE_PARAM}?locale=${locale}`;
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteAlert({ locale }: DefaultRequestData): Promise<WebsiteAlert[]> {
    const url = `${WEBSITE_ALERT}?locale=${locale}`;
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteComponentAlert({
    locale,
    url,
  }: RequestWebsiteComponentAlert): Promise<ResponseWebsiteComponentAlert> {
    const urlRequest = `${WEBSITE_COMPONENT_ALERT}?url=${url}&locale=${locale}`;
    const res = await this.unAuthAxios().get(this.endpointUrl(urlRequest));
    return res.data.data;
  }

  async healthCheckNetwork(network: string) {
    const url = `${HEALTHCHECK_NETWORK}${network}/`;
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data;
  }

  async websiteIntegrationQuestionByTag(tag: string): Promise<IntegrationQuestion[]> {
    const url = WEBSITE_QUESTION_BY_GROUP_TAG.replace('{tag}', tag);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteOfficeMap(
    data: OfficeRequestData,
    filter?: Partial<CdekOfficeFilter>,
  ): Promise<OfficeMapDto[]> {
    const url = getOfficeUrl(WEBSITE_OFFICE_MAP, data, filter);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data.data;
  }

  async websiteOffice(data: OfficeRequestData, filter: Partial<CdekOfficeFilter>) {
    let url = getOfficeUrl(WEBSITE_OFFICE, data, filter);
    url += '&limit=100';
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data.data;
  }

  async websiteIntegrationModule(): Promise<IntegrationShortModule[]> {
    const res = await this.unAuthAxios().get(this.endpointUrl(WEBSITE_INTEGRATION_MODULE));
    return res.data.data;
  }

  async websiteIntegrationModuleById(id: string): Promise<IntegrationModuleResponse> {
    const url = WEBSITE_INTEGRATION_MODULE_BY_ID.replace('{id}', id);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteFooter({ locale, cityId }: FooterRequestData): Promise<FooterData> {
    let url = `${WEBSITE_FOOTER}?locale=${locale}`;

    if (cityId) {
      url += `&cityId=${cityId}`;
    }

    const res = await this.unAuthCachedGet(url);

    return res.data.data;
  }

  async getCdekShoppingFeaturedProducts({ locale = 'ru', category, limit, sourceType }: any) {
    let url = OTHER_CDEK_SHOPPING_FEATURED_PRODUCTS.replace('{locale}', locale);

    if (category) {
      url += `&category[]=${category}`;
    }

    if (limit) {
      url += `&limit=${limit}`;
    }

    if (sourceType) {
      url += `&sourceType=${sourceType}`;
    }

    const res = await this.unAuthAxios().get(this.endpointUrl(encodeURI(url)));
    return res.data.data;
  }

  async getCdekShoppingFeaturedProductsCategory({ locale }: DefaultRequestData) {
    const url = OTHER_CDEK_SHOPPING_FEATURED_PRODUCTS_CATEGORY.replace('{locale}', locale);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async autodetectCity({ locale }: DefaultRequestData): Promise<AutodetectCityResponse> {
    const url = WEBSITE_CITY_DETECT.replace('{locale}', locale);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteNews({ locale, sort, limit, page }: News) {
    const url = WEBSITE_NEWS.replace('{locale}', locale)
      .replace('{sort}', sort)
      .replace('{limit}', limit)
      .replace('{page}', page);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async helpWidget({ locale = 'ru' }: DefaultRequestData, path = null) {
    const url = `${WEBSITE_HELP_WIDGET}?locale=${locale}&url=${path}`;
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async cityAutocomplete(
    name: string,
    locale: string,
    countryCode?: string,
  ): Promise<CityAutocomplete[]> {
    let url = WEBSITE_CITY_AUTOCOMPLETE.replace('{name}', name).replace('{locale}', locale);

    if (countryCode) {
      url += `&countryCode=${countryCode}`;
    }

    const res = await this.unAuthAxios().post(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteNotificationSendJira(feedback: unknown) {
    return this.unAuthAxios().post(this.endpointUrl(WEBSITE_NOTIFICATION_SEND_JIRA), feedback);
  }

  async websiteNotificationSendJiraOI(feedback: unknown) {
    return this.unAuthAxios().post(this.endpointUrl(WEBSITE_NOTIFICATION_SEND_JIRA_OI), feedback);
  }

  async getOfficeWorkload(officeCode: string) {
    const url = WEBSITE_WORKLOAD.replace('{officeCode}', officeCode);
    const res = await this.unAuthAxios().post(this.endpointUrl(url));
    return res.data.data;
  }

  async getOfficeVideoStream(id: string, locale: string, device: string | null = null) {
    let url = WEBSITE_VIDEO_STREAM_OFFICE.replace('{id}', id).replace('{locale}', locale);

    if (device) {
      url += `&device=${device}`;
    }
    const res = await this.unAuthAxios().post(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteDogovorCreateIndividual(data: unknown) {
    return this.unAuthAxios().post(this.endpointUrl(WEBSITE_DOGOVOR_CREATE_INDIVIDUAL), data);
  }

  async websiteDogovorCreateBusiness(data: unknown) {
    return this.unAuthAxios().post(this.endpointUrl(WEBSITE_DOGOVOR_CREATE_BUSINESS), data);
  }

  async websiteDogovorCreateIp(data: unknown) {
    return this.unAuthAxios().post(this.endpointUrl(WEBSITE_DOGOVOR_CREATE_IP), data);
  }

  async websiteDogovorBik(bik: string, locale: string) {
    const url = WEBSITE_DOGOVOR_BIK.replace('{bik}', bik).replace('{locale}', locale);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data;
  }

  async websiteDogovorInn(inn: string, locale: string) {
    const url = WEBSITE_DOGOVOR_INN.replace('{inn}', inn).replace('{locale}', locale);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data;
  }

  async getCountries(locale: string) {
    const url = WEBSITE_COUNTRY.replace('{locale}', locale);

    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteIdentityParams(data: unknown) {
    const res = await this.unAuthAxios().post(this.endpointUrl(WEBSITE_IDENTITY_PARAMS), data);
    return res.data;
  }

  async uploadPassportFile(data: unknown) {
    const res = await this.unAuthAxios().post(
      `${import.meta.env.VITE_HTTP_ENDPOINT}/api-site/v1/upload/passport-file/`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  }

  async sendPassportData({ citizenship, operationId, formData }: any) {
    const url = WEBSITE_IDENTITY_PASSPORT.replace('{citizenship}', citizenship).replace(
      '{operationId}',
      operationId,
    );
    const res = await this.unAuthAxios().post(this.endpointUrl(url), formData);
    return res.data;
  }

  async sendIdCardData({ citizenship, operationId, formData }: any) {
    const url = WEBSITE_IDENTITY_ID_CARD.replace('{citizenship}', citizenship).replace(
      '{operationId}',
      operationId,
    );
    const res = await this.unAuthAxios().post(this.endpointUrl(url), formData);
    return res.data;
  }

  async sendOtherDocumentData({ operationId, formData }: any) {
    const url = WEBSITE_IDENTITY_OTHER_DOCUMENT.replace('{operationId}', operationId);
    const res = await this.unAuthAxios().post(this.endpointUrl(url), formData);
    return res.data;
  }

  async sendCdekIdData({
    citizenship = 'ru',
    operationId,
    formData,
  }: {
    citizenship: string;
    operationId: string;
    formData: { personUuid: string; wantCdekId: boolean };
  }): Promise<{ success: true; code: number; error: any; data: { created: boolean } | null }> {
    const url = WEBSITE_IDENTITY_CDEK_ID.replace('{citizenship}', citizenship).replace(
      '{operationId}',
      operationId,
    );
    const res = await this.unAuthAxios().post(this.endpointUrl(url), formData);
    return res.data;
  }

  async websiteSitemap(locale: string) {
    const url = WEBSITE_SITEMAP.replace('{locale}', locale);

    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async sendPackageForm(data: unknown) {
    const res = await this.unAuthAxios().post(
      this.endpointUrl(WEBSITE_NOTIFICATION_PACKAGE_ORDER),
      data,
    );
    return res.data;
  }

  async getFrozenLoyaltyPoints(user_uuid: string) {
    const url = WEBSITE_FREEZED_LOYALTY_POINTS.replace('{user_uuid}', user_uuid);
    const res = await this.unAuthAxios().post(this.endpointUrl(url));
    return res.data;
  }

  async ratingProductAdd(
    rating: number,
    comment: string,
    user_uuid: string | null,
    phone: string | null,
  ) {
    const res = await this.unAuthAxios().post(this.endpointUrl(WEBSITE_RATING_PRODUCT_ADD), {
      rating,
      comment,
      user_uuid,
      phone,
    });
    return res.data;
  }

  async agreementOnlineFnsCard(inn: string, data: DefaultRequestData): Promise<FnsCard> {
    const url = WEBSITE_AGREEMENT_ONLINE_FNS_CARD.replace('{inn}', inn).replace(
      '{locale}',
      data.locale,
    );

    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data;
  }

  async websiteAgreementJoin(data: JoinCompanyRequest, { locale }: DefaultRequestData) {
    const url = WEBSITE_AGREEMENT_ONLINE_JOIN.replace('{locale}', locale);

    const res = await this.unAuthAxios().post(this.endpointUrl(url), data);

    return res.data.data;
  }

  async websiteOrderCheck(order: number) {
    const url = WEBSITE_ORDER_CHECK.replace('{order}', `${order}`);

    return this.unAuthAxios().post(this.endpointUrl(url));
  }

  async websiteCityByCoords(
    position: GeolocationCoordinates,
    locale: string,
  ): Promise<CityAutocomplete> {
    const url = WEBSITE_CITY_BY_COORDS.replace('{locale}', locale)
      .replace(`{latitude}`, `${position.latitude}`)
      .replace(`{longitude}`, `${position.longitude}`);

    return (await this.unAuthAxios().get(this.endpointUrl(url))).data.data;
  }

  async websiteAgreementConclusion(
    data: ConclusionRequest,
    { locale }: DefaultRequestData,
  ): Promise<ContragentInformation> {
    let url = WEBSITE_AGREEMENT_ONLINE_CONCLUSION.replace('{locale}', locale)
      .replace('{inn}', data.inn)
      .replace('{cityUuid}', data.cityUuid);

    if (data.kpp) {
      url = `${url}&kpp=${data.kpp}`;
    }

    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data;
  }

  async websiteAgreementByRequisites(inn: string): Promise<{ contractUuid: string }> {
    const url = WEBSITE_AGREEMENT_ONLINE_BY_REQUISTIES.replace('{inn}', inn);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data.data;
  }

  async websiteAgreementContract(locale: string, body: unknown): Promise<{ contractUuid: string }> {
    const url = WEBSITE_AGREEMENT_ONLINE_CONTRACT.replace('{locale}', locale);
    const res = await this.unAuthAxios().post(this.endpointUrl(url), body);
    return res.data.data;
  }

  async websiteAgreementSelfcare(
    data: ContragentInformation,
    { locale }: DefaultRequestData,
  ): Promise<SelfcareResponse> {
    let url = WEBSITE_AGREEMENT_ONLINE_SELFCARE.replace('{locale}', locale);

    if (data.contractUuid) {
      url = `${url}&contractUuid=${data.contractUuid}`;
    }

    if (data.contragentUuid) {
      url = `${url}&contragentUuid=${data.contragentUuid}`;
    }

    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data;
  }

  async websiteAgreementCreateNew(data: BaseCreationRequest, locales: DefaultRequestData) {
    const url = WEBSITE_AGREEMENT_ONLINE_CREATE_NEW.replace('{locale}', locales.locale);

    const res = await this.unAuthAxios().post(this.endpointUrl(url), data);

    return res.data.data;
  }

  async websiteAgreementCreateExist(data: ExistsCreationRequest, { locale }: DefaultRequestData) {
    const url = WEBSITE_AGREEMENT_ONLINE_CREATE_EXIST.replace('{locale}', locale);

    const res = await this.unAuthAxios().post(this.endpointUrl(url), data);

    return res.data.data;
  }

  async websiteCity(
    data: CdekCityRequest,
    { locale }: DefaultRequestData,
  ): Promise<CdekFullCity[]> {
    let url = WEBSITE_CITY.replace('{locale}', locale);

    Object.entries(data).forEach(([key, value]) => {
      url = `${url}&${key}=${value}`;
    });

    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data.data;
  }

  async websiteFaq(locale: string, withFooter = false): Promise<IWebsiteFaqData> {
    const url = WEBSITE_FAQ.replace('{locale}', locale).replace(`{withFooter}`, `${withFooter}`);

    return (await this.unAuthAxios().get(this.endpointUrl(url))).data.data;
  }

  async websitePage(url: string, locale: string): Promise<IWebsitePageData> {
    const requestUrl = WEBSITE_PAGE.replace('{locale}', locale).replace(
      `{url}`,
      `${removeSideSlashes(url)}/`,
    );

    const res = await this.unAuthCachedGet(requestUrl);

    return res.data.data;
  }

  async websitePaymentAuthCodeVerify(
    params: IPaymentVerifyParams,
  ): Promise<IPaymentVerifyResponse> {
    const res = await this.unAuthAxios().post(
      this.endpointUrl(WEBSITE_PAYMENT_AUTH_CODE_VERIFY),
      params,
    );

    return res.data.data;
  }

  async yandexGeoSuggest(text: string, locale = 'ru'): Promise<string[]> {
    const url = OTHER_YANDEX_GEO_SUGGEST.replace('{text}', text).replace('{locale}', locale);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));

    return res.data.data;
  }

  async getServices(data: IServicesRequest): Promise<IServicesResponse> {
    const res = await this.unAuthAxios().post(this.endpointUrl(CALCULATOR_SERVICES), data);

    return res.data.data;
  }

  async calculate(data: ICalculateRequest): Promise<ICalculateResponse> {
    const res = await this.unAuthAxios().post(this.endpointUrl(CALCULATOR_CALCULATE), data);

    return res.data.data;
  }

  async createBusinessForm(data: FormData): Promise<ICreateBusinessFormResponse> {
    const res = await this.unAuthAxios().post(
      this.endpointUrl(WEBSITE_DOGOVOR_CREATE_BUSINESS_FORM),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return res.data.data;
  }

  async customerGetLink(number: string, id: string, locale = 'ru'): Promise<string> {
    const url = CUSTOMER_GET_LINK.replace('{locale}', locale)
      .replace('{number}', number)
      .replace('{id}', id);
    const res = await this.unAuthAxios().get(this.endpointUrl(url));
    return res.data?.data || '';
  }
}
