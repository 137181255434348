import { MutationTree } from 'vuex';
import { CabinetState } from '@/types/store/cabinet';
import {
  getEmptyTracking,
  initialCabinet,
} from '@/store/vuex-store/modules/cabinetModule/cabinetModuleState';

const cabinetModuleMutations: MutationTree<CabinetState> = {
  /**
   * @param {Object} state
   * @param {String} payload
   */
  setLoginPhone(state, payload) {
    state.login_phone = payload;
  },

  updateRedirectedFromSiteCdekid(state, payload) {
    state.redirectedFromSiteCdekid = payload;
  },

  updateTrackId(state, payload) {
    state.tracking.trackId = payload.trackId;
  },

  recordTrackingData(state, payload) {
    state.tracking.trackInfo = payload;
  },

  resetState(state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = { ...initialCabinet(), ...{ isStateLogout: true } };
  },

  setUser(state, value) {
    state.user = value;
  },

  touchLastDateIfUserLoaded(state) {
    state.lastDateUserLoaded = new Date();
  },
  setUserProfile(state, value) {
    state.user = { ...state.user, profile: value };
  },

  setUserContragent(state, value) {
    state.user = { ...state.user, contragent: value };
  },

  setUserContragentUUID(state, value) {
    state.user = { ...state.user, contragent_uuid: value };
  },
  updateUserInn(state, value) {
    state.user.profile.inn = value;
  },
  setUserEmail(state, payload: string) {
    state.user.profile.email = payload;
    state.user.profile.is_email_confirmed = false;
  },
  setUserEmailConfirmed(state, payload = true) {
    state.user.profile.is_email_confirmed = payload;
  },
  setTrackingHistory(state, payload) {
    // TODO: SET TYPE INSTEAD OF ANY.
    state.tracking.trackingHistory = payload.map((o: any) => {
      o.cashOnDeliveryIndividual = o.cash_on_delivery_individual
        ? o.cash_on_delivery_individual.cost
        : 0;

      return o;
    });
  },
  addOrderCashOnDeliveryStatus(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((o) => {
      if (o.orderNumber === payload.orderNumber) {
        return { ...o, cashOnDeliveryStatus: { ...payload } };
      }
      return o;
    });
  },
  addOrderDetails(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }
    state.tracking.trackingHistory = state.tracking.trackingHistory.map((o) => {
      if (o.orderNumber === payload.orderNumber) {
        return { ...payload.order, detailsState: o.detailsState };
      }
      return o;
    });
  },
  updateOrderDetails(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === payload.orderNumber) {
        return { ...payload.order };
      }
      return t;
    });
  },
  showFullOrderInfo(state, { orderNumber, fullOrderDetails = null }) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === orderNumber) {
        return { ...t, fullOrderDetails, showFullDetails: !t.showFullDetails };
      }
      return t;
    });
  },
  changeOrderDetailsState(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === payload.orderNumber) {
        return { ...t, detailsState: payload.detailsState };
      }
      return t;
    });
  },
  updateAvailableTime(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === payload.orderNumber) {
        return {
          ...t,
          availableTimeForDelivery: payload.availableTimeForDelivery,
        };
      }
      return t;
    });
  },
  updateReceiverAddress(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === payload.orderNumber) {
        return { ...t, receiverAddress: payload.receiverAddress };
      }
      return t;
    });
  },
  updateCanBeChanged(state, payload) {
    if (!state.tracking.trackingHistory) {
      return;
    }

    state.tracking.trackingHistory = state.tracking.trackingHistory.map((t) => {
      if (t.orderNumber === payload.orderNumber) {
        return { ...t, canBeChanged: payload.canBeChanged };
      }
      return t;
    });
  },
  resetTracking(state) {
    state.tracking = getEmptyTracking();
  },
  setCdekidStatus(state, value) {
    state.user = { ...state.user, cdekid_status: value };
  },
  setStartCreateOrderAtSite(state, value) {
    state.startCreateOrderAtSite = value;
  },
  setStartCreateOrderAtBox(state, value) {
    state.startCreateOrderAtBox = value;
  },
  setIsRedirectedFromSite(state, payload) {
    state.isRedirectedFromSiteOrderCreate = payload;
  },
  setOrdersHasUpdates(state, payload) {
    state.ordersHasUpdates = payload;
  },
  updateHiddenAlerts(state, payload) {
    const newValue = [...state.hiddenAlerts, payload];
    state.hiddenAlerts = [...new Set(newValue)];
  },
  setIsChangeOrder(state, payload) {
    state.isChangeOrder = payload;
  },
  setIsHideOrderCreditBtn(state, payload) {
    state.isHideOrderCreditBtn = payload;
  },
  setWebviewToken(state, payload) {
    state.webviewToken = payload;
  },
  setIsStateLogout(state, payload = true) {
    state.isStateLogout = payload;
  },
  setIsStateLoginProcessing(state, payload = false) {
    state.isStateLoginProcessing = payload;
  },
  setAgreement(state, payload) {
    state.agreement = { ...state.agreement, ...payload };
  },
};
export default cabinetModuleMutations;
