import { COOKIE_CITY_ID_TAG } from '@/constants/cookie-tag';
import { cdekConsoleError } from '@/utils/console-wrapper';
import { SET_SHOW_CONFIRM_CITY } from '@/middleware/mutation-types';
import { inject } from 'vue';
import { VueCookies } from 'vue-cookies';
import { useRestApi } from '@/composables/use-rest-api';
import useSiteInfo from '@/composables/use-site-info';
import { useSelectedCity } from '@/composables/use-selected-city';
import { useStore } from 'vuex';
import { CdekDefaultCity } from '@/types/dto/city';

export const useAutodetectCity = () => {
  const $cookies = inject<VueCookies>('$cookies');
  const autodetectCity = async () => {
    const cityId = $cookies?.get(COOKIE_CITY_ID_TAG);
    const { restApi } = useRestApi();
    const { currentLocaleISO } = useSiteInfo();
    const { selectGlobalCity } = useSelectedCity();
    const store = useStore();

    if (!cityId) {
      try {
        const { detected, default: defaultCity } = await restApi.autodetectCity({
          locale: currentLocaleISO.value,
        });
        const city = detected || defaultCity;

        if (city) {
          selectGlobalCity(city as unknown as CdekDefaultCity);
        }

        if (!detected) {
          return;
        }
      } catch (e) {
        cdekConsoleError(e);
      } finally {
        store.dispatch(SET_SHOW_CONFIRM_CITY, true);
      }
    }
  };

  return {
    autodetectCity,
  };
};
