import mitt from 'mitt';
import type { App } from 'vue';

export default {
  install: (app: App) => {
    const emitter = mitt();

    app.config.globalProperties.$emitter = emitter;

    app.provide('emitter', emitter);
  },
};
