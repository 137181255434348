import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { castEnv } from '@/utils/env-utils';

export default {
  install(app: App) {
    const environment =
      import.meta.env.VITE_ENVIRONMENT || (import.meta.env.DEV ? 'development' : 'production');

    const release = import.meta.env.VITE_BUILD_RELEASE || '';

    const sampleRate = import.meta.env.VITE_SITE_SENTRY_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_SITE_SENTRY_SAMPLE_RATE)
      : 0.1;

    Sentry.init({
      app,
      environment,
      dsn: import.meta.env.VITE_SITE_SENTRY_DSN,
      tracesSampleRate: sampleRate,
      enabled: !castEnv(import.meta.env.VITE_SITE_SENTRY_DISABLED),
      trackComponents: true,
      attachStacktrace: true,
      replaysSessionSampleRate: sampleRate,
      integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
      release,
      ignoreErrors: [/Network Error/i],
      beforeSend(event, hint) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const errorMessage = hint.originalException?.message;

        if (errorMessage && errorMessage.includes('Importing a module script failed')) {
          return null;
        }

        if (errorMessage && errorMessage.includes('Failed to fetch dynamically imported module')) {
          return null;
        }

        return event;
      },
      denyUrls: [/i\.v2\.flomni\.com/i, /mindbox\.ru/i, /yastatic\.net/i],
    });

    app.config.globalProperties.$sentry = Sentry;
    app.provide('sentry', Sentry);
  },
};
