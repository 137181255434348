export function hitYm(urlPathname) {
  window.ym && window.ym(import.meta.env.VITE_YANDEX_METRIKA_ID, 'hit', urlPathname);
}
export function initYmPlugin() {
  const id = import.meta.env.VITE_YANDEX_METRIKA_ID;

  if (!id) return;

  /* eslint-disable */
  (function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * new Date();

    for (let j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) {
        return;
      }
    }

    (k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r),
      a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  /* eslint-enable */

  // Сказали только на ру включить, остальное как есть
  const webvisor = import.meta.env.VITE_WEBSITE_ID === 'ru';

  // Параметры из админки яндекc метрики
  window.ym(id, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor,
    ecommerce: 'dataLayer',
  });
}
