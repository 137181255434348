import {
  CITIZENSHIP_ID_MAP,
  DOCUMENTS_TYPES,
  PASSPORT_LOADING_STATUSES,
  PASSPORT_PAGE_STEPS,
} from '@/constants/passport-v2';

const defaultState = () => ({
  pageStep: PASSPORT_PAGE_STEPS.CODE,
  // по linkCode будет определяться флоу, если он есть
  // то значит мы на странице с гет-параметром, если его нет
  // то мы используем токены
  linkCode: '',
  // серьезность проверки, 1 раз проверяем строго, последующие слабо
  severity: false,
  citizenship: null,
  otherCitizenshipIsoCode: null,
  formData: {},
  documentType: DOCUMENTS_TYPES.PASSPORT,
  destinationCountryCode: null,
  operationId: null,
  orderNumber: null,
  scanRequired: null,
  files: [],
  sendFilesStatus: '',
  sendFormStatus: '',
  sendFilesErrors: [],
  sendFormErrors: [],
  personUuid: '',
});

export const state = () => defaultState();

export const mutations = {
  setLinkCode(state, code) {
    state.linkCode = code;
  },
  turnOffSeverity(state) {
    state.severity = true;
  },
  setPageStep(state, payload) {
    state.pageStep = payload;
  },
  setDocumentType(state, payload) {
    state.documentType = payload;
  },
  setCitizenship(state, payload) {
    state.citizenship = payload;
  },
  setOtherCitizenshipIsoCode(state, code) {
    state.otherCitizenshipIsoCode = code;
  },
  updateFormData(state, payload) {
    state.formData = { ...state.formData, ...payload };
  },
  putFile(state, payload) {
    state.files[payload.fileNumber] = payload.file;
  },
  removeFile(state, id) {
    state.files = state.files.map((f) => {
      if (f.id === id) {
        return {};
      }
      return f;
    });
  },
  clearFiles(state) {
    state.files = [];
  },
  setOperationId(state, id) {
    state.operationId = id;
  },
  setDestinationCountryCode(state, code) {
    state.destinationCountryCode = code;
  },
  updateSendFilesStatus(state, status) {
    state.sendFilesStatus = status;
  },
  updateSendFilesErrors(state, errors) {
    state.sendFilesErrors = errors;
  },
  updateSendFormStatus(state, status) {
    state.sendFormStatus = status;
  },
  updateSendFormErrors(state, errors) {
    state.sendFormErrors = errors;
  },
  resetFormState(state) {
    const newState = defaultState();
    state.pageStep = newState.pageStep;
    state.linkCode = newState.linkCode;
    state.citizenship = newState.citizenship;
    state.otherCitizenshipIsoCode = newState.otherCitizenshipIsoCode;
    state.formData = newState.formData;
    state.documentType = newState.documentType;
    state.destinationCountryCode = newState.destinationCountryCode;
    state.operationId = newState.operationId;
    state.orderNumber = newState.orderNumber;
    state.scanRequired = newState.scanRequired;
    state.files = newState.files;
    state.sendFilesStatus = newState.sendFilesStatus;
    state.sendFormStatus = newState.sendFormStatus;
    state.sendFormErrors = newState.sendFormErrors;
    state.personUuid = newState.personUuid;
  },
  resetFormData(state) {
    const newState = defaultState();
    state.citizenship = newState.citizenship;
    state.otherCitizenshipIsoCode = newState.otherCitizenshipIsoCode;
    state.formData = newState.formData;
    state.documentType = newState.documentType;
    state.files = newState.files;
    state.sendFilesStatus = newState.sendFilesStatus;
    state.sendFormStatus = newState.sendFormStatus;
    state.sendFormErrors = newState.sendFormErrors;
    state.personUuid = newState.personUuid;
  },
  setPersonUuid(state, payload) {
    state.personUuid = payload;
  },
};

export const actions = {
  setLinkCode({ commit, state }, code) {
    if (state.linkCode !== code) {
      commit('resetFormState');
    }
    commit('setLinkCode', code);
  },
  updateSendFilesStatus({ commit }, status) {
    if (status === PASSPORT_LOADING_STATUSES.SUCCESS) {
      commit('setPageStep', PASSPORT_PAGE_STEPS.FORM_UPLOAD_FILES);
    } else {
      commit('setPageStep', PASSPORT_PAGE_STEPS.FORM_SEND_FILES);
    }
    commit('updateSendFilesStatus', status);
  },
  updateSendFormStatus({ commit }, status) {
    commit('setPageStep', PASSPORT_PAGE_STEPS.FORM_SEND_FORM);
    commit('updateSendFormStatus', status);
  },
};

export const getters = {
  pageStep: ({ pageStep }) => pageStep,
  documentType: ({ documentType }) => documentType,
  citizenship: ({ citizenship }) => citizenship,
  otherCitizenshipIsoCode: ({ otherCitizenshipIsoCode }) => otherCitizenshipIsoCode,
  destinationCountryCode: ({ destinationCountryCode }) => destinationCountryCode,
  formData: ({ formData }) => formData,
  files: ({ files }) => files,
  operationId: ({ operationId }) => operationId,
  sendFilesStatus: ({ sendFilesStatus }) => sendFilesStatus,
  sendFormStatus: ({ sendFormStatus }) => sendFormStatus,
  sendFilesErrors: ({ sendFilesErrors }) => sendFilesErrors,
  sendFormErrors: ({ sendFormErrors }) => sendFormErrors,
  isPassport: ({ documentType }) => documentType === DOCUMENTS_TYPES.PASSPORT,
  isIdCard: ({ documentType }) => documentType === DOCUMENTS_TYPES.ID_CARD,
  isOtherDocument: ({ documentType }) => documentType === DOCUMENTS_TYPES.OTHER,
  isRuCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.RU,
  isKzCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.KZ,
  isKgCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.KG,
  isByCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.BY,
  isUzCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.UZ,
  isAMCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.AM,
  isGECitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.GE,
  isAZCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.AZ,
  isILCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.IL,
  isOtherCitizenship: ({ citizenship }) => citizenship === CITIZENSHIP_ID_MAP.OTHER,
  linkCode: ({ linkCode }) => linkCode,
  severity: ({ severity }) => severity,
  personUuid: ({ personUuid }) => personUuid,
};

export const passportFormModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
