import { VueI18nTranslation } from 'vue-i18n';

const appStoreId = '1384376966';
const googlePlayId = 'com.logistic.sdek';

export function getDefaultMetaData(t: VueI18nTranslation) {
  return {
    title: t('defaultCdekTitle'),
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, maximum-scale=5, minimal-ui',
      },
      { name: 'msapplication-TileColor', content: '#da532c' },
      { name: 'theme-color', content: '#ffffff' },
      { name: 'format-detection', content: 'telephone=no' },
      {
        hid: 'description',
        name: 'description',
        content: t('defaultCdekDescription'),
      },
      {
        name: 'yandex-verification',
        content: import.meta.env.VITE_YANDEX_VERIFICATION,
      },
      {
        name: 'apple-itunes-app',
        content: `app-id=${appStoreId}`,
      },
      {
        name: 'google-play-app',
        content: `app-id=${googlePlayId}`,
      },
      {
        name: 'facebook-domain-verification',
        content: import.meta.env.VITE_FACEBOOK_DOMAIN_VERIFICATION,
      },
      {
        name: 'color-scheme',
        content: 'light',
      },
      {
        name: 'msapplication-navbutton-color',
        content: '#d9f5ff',
      },
      {
        name: 'msapplication-TileImage',
        content: '/favicons/android-chrome-192x192.png',
      },
      {
        name: 'apple-mobile-web-app-status-bar-style',
        content: '#3d6eff',
      },
      {
        name: 'application-name',
        content: 'CDEK',
      },
      {
        name: 'apple-mobile-web-app-title',
        content: 'CDEK',
      },
    ],
    link: [
      {
        href: '/favicons/favicon.svg',
        rel: 'icon',
        type: 'image/svg+xml',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicons/favicon-16x16.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicons/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '38x38',
        href: '/favicons/favicon-38x38.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '72x72',
        href: '/favicons/favicon-72x72.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '128x128',
        href: '/favicons/favicon-128x128.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '152x152',
        href: '/favicons/favicon-152x152.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/favicons/favicon-192x192.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '512x512',
        href: '/favicons/favicon-512x512.png',
      },
      { rel: 'manifest', href: '/site.webmanifest' },
      {
        rel: 'mask-icon',
        color: '#5bbad5',
        href: '/favicons/safari-pinned-tab.svg',
      },
      { rel: 'icon', type: 'image/x-icon', href: '/favicons/favicon.ico' },
      { rel: 'apple-touch-icon', href: '/smartbanner/apple-touch-icon.png' },
      { rel: 'android-touch-icon', href: '/smartbanner/android-icon.png' },
    ],
    script: [
      {
        innerHTML: 'window.suggestmeyes_loaded = true;',
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        // На страницах тильды есть скрипты прямо в верстке которые используют функцию t_onReady.
        // Скрипты в которых создается t_onReady загружаются позже чем бразуер в первый раз вызывает скрипты из верстки
        // Чтобы на этом этапе ничего не разваливалось, добавляю эту пустую функцию.
        innerHTML: 'window.t_onReady = function() {}',
        type: 'text/javascript',
      },
    ],
  };
}
