import {
  COUNTRIES_TO,
  COUNTRIES_FROM,
  SUPER_EXPRESS_TARIFF_IDS,
  PARCEL_TARIFF_ID,
  PROFI_TARIFF_OFFICE_OFFICE_ID,
  PROFI_TARIFF_OFFICE_POSTAMAT_ID,
} from '@/components/NewOrder/constants';
import { getDefaultCurrencyByWebsiteId, isFirstTab } from '@/components/NewOrder/utils';
import { GetterTree } from 'vuex';
import MainState from '@/types/store';
import { CreateOrderState } from '@/types/store/createOrder';

const createOrderGetters: GetterTree<CreateOrderState, MainState> = {
  isSizeValid({ direction }) {
    return !!direction.size;
  },
  isDirectionValid({ direction }, getters) {
    return direction.from && direction.to && direction.size && getters.isSizeValid;
  },
  currency({ activeCurrency }, getters) {
    return activeCurrency ?? getters.websiteDefaultCurrency;
  },
  websiteDefaultCurrency(state, getters, rootState, rootGetters) {
    const currency = getDefaultCurrencyByWebsiteId(rootGetters.websiteId);

    return currency ?? getters.currency;
  },
  isLiteOrder(state, getters) {
    return !getters.isFullOrder;
  },
  isFullOrder() {
    // на данный момент используется на всех сайтах
    // в будущем будем добавлять исключение !rootGetters.isEnSite
    return true;
  },
  isAuthOrder(state, getters, rootState, rootGetters) {
    // Оставляем данный геттер, чтобы в коде понимать, что заказ идет в авторизованную зону
    return rootGetters['auth/enabledLK'];
  },
  payersCurrency({ payerCurrencies }, getters) {
    return payerCurrencies.find((currency) => currency.default) || getters.websiteDefaultCurrency;
  },
  isCurrencyRub(state, getters) {
    return getters.currency && getters.currency.shortName === 'RUB';
  },
  isPayersCurrencyRub(state, getters) {
    return getters.payersCurrency && getters.payersCurrency.shortName === 'RUB';
  },
  isOrderInsideRussia({ direction }) {
    const { from, to } = direction;
    if (from && to) {
      return from.countryCode === 1 && to.countryCode === 1;
    }

    return false;
  },

  isGlobalLoading({ isRequestCalculate, isRequestDirection }) {
    return isRequestCalculate || isRequestDirection;
  },
  isDifferentCountries({ direction }) {
    return (
      !COUNTRIES_FROM.includes(direction.from?.countryCode as number) ||
      !COUNTRIES_TO.includes(direction.to?.countryCode as number)
    );
  },
  isCurrentTariffParcel({ currentTariff }) {
    return currentTariff && currentTariff.ec4Id === PARCEL_TARIFF_ID;
  },
  isSuperExpress({ currentTariff }) {
    return currentTariff && SUPER_EXPRESS_TARIFF_IDS.includes(currentTariff.ec4Id);
  },
  isTariffProfiAvailable: (state) => {
    return (
      state.currentTab &&
      isFirstTab(state.currentTab) &&
      state.currentTab.tariffs.some((tariff) => tariff.ec4Id === PROFI_TARIFF_OFFICE_OFFICE_ID)
    );
  },
  isTariffProfiToPostamatAvailable: (state) => {
    return (
      state.currentTab &&
      isFirstTab(state.currentTab) &&
      state.currentTab.tariffs.some((tariff) => tariff.ec4Id === PROFI_TARIFF_OFFICE_POSTAMAT_ID)
    );
  },
};

export default createOrderGetters;
