import { GetterTree } from 'vuex';
import { CabinetState } from '@/types/store/cabinet';
import MainState from '@/types/store';
import { initialCabinet } from '@/store/vuex-store/modules/cabinetModule/cabinetModuleState';

const cabinetModuleGetters: GetterTree<CabinetState, MainState> = {
  ordersHasUpdates: (state) => state.ordersHasUpdates,
  fullName(state) {
    if (state.user?.profile && (state.user.profile.firstName || state.user.profile.surName)) {
      const { firstName, surName } = state.user.profile;
      return [firstName, surName].filter(Boolean).join(' ');
    }

    return '';
  },
  isActiveCdekId(state) {
    return state.user.contragent?.cdekId;
  },
  getLoginPhone(state) {
    return state.login_phone;
  },
  getLoginPhoneLastDigits(state) {
    if (!state.login_phone) {
      return '';
    }

    return state.login_phone.substr(8);
  },
  trackId({ tracking }) {
    return tracking.trackId;
  },
  tracking({ tracking }) {
    return tracking.trackInfo;
  },
  getUser(state) {
    return state.user;
  },
  getUserProfile(state) {
    if (!state.user) {
      return initialCabinet().user.profile;
    }

    return state.user.profile;
  },
  getUserInn(state) {
    return state.user.profile.inn;
  },
  getUserEmail(state) {
    return state.user.profile?.email;
  },
  isUserEmailConfirmed(state) {
    return state.user.profile?.is_email_confirmed;
  },
  getLastDateUserLoaded: ({ lastDateUserLoaded }) => lastDateUserLoaded,
  getStartCreateOrderAtSite(state) {
    return state.startCreateOrderAtSite;
  },

  getStartCreateOrderAtBox: (state) => state.startCreateOrderAtBox,
  // order

  getTrackingHistory({ tracking }) {
    return tracking.trackingHistory;
  },
  getOrderFromTrackingHistory(state, orderNumber) {
    if (!state.tracking.trackingHistory) {
      return [];
    }
    return state.tracking.trackingHistory.filter((o) => o.orderNumber === orderNumber);
  },
  getLoadingTrackingHistory({ tracking }) {
    return tracking.loadingTrackingHistory;
  },
  getIsRedirectedFromSite({ isRedirectedFromSiteOrderCreate }) {
    return isRedirectedFromSiteOrderCreate;
  },

  // cdekid

  getRedirectedFromSiteCdekid({ redirectedFromSiteCdekid }) {
    return redirectedFromSiteCdekid;
  },
  hiddenAlerts({ hiddenAlerts }) {
    return hiddenAlerts;
  },
  isChangeOrder: ({ isChangeOrder }) => isChangeOrder,
  isHideOrderCreditBtn: ({ isHideOrderCreditBtn }) => isHideOrderCreditBtn,
  isStateLogout: ({ isStateLogout }) => isStateLogout,
  isStateLoginProcessing: ({ isStateLoginProcessing }) => isStateLoginProcessing,
};
export default cabinetModuleGetters;
