import type { App } from 'vue';

import { $uiBaseColor } from '@/constants/default-colors';

export default {
  install: (app: App) => {
    app.config.globalProperties.$uiBaseColor = $uiBaseColor;
    app.provide('uiBaseColor', $uiBaseColor);
  },
};
