import { FlomniState } from '@/types/store/flomni';
import { GetterTree, Module, MutationTree } from 'vuex';
import MainState from '@/types/store';

const state = (): FlomniState => ({
  displayChat: false,
  state: 'close',
  inline: false,
  isYaMapsBalloonOpened: false,
  isHelpCustomerLoaded: false,
  isExternalOpened: false,
});

const mutations: MutationTree<FlomniState> = {
  onInit(store, payload) {
    store.displayChat = payload.displayChat;
    store.inline = payload.inline;
  },
  onUpdateState(store, payload) {
    store.state = payload.state;
  },
  updateYaMapsBalloonOpened(store, payload) {
    store.isYaMapsBalloonOpened = payload;
  },
  setIsHelpCustomerLoaded(state, payload = true) {
    state.isHelpCustomerLoaded = payload;
  },
  setIsExternalOpened(state, payload = true) {
    state.isExternalOpened = payload;
  },
  toggleIsExternalOpened(state) {
    state.isExternalOpened = !state.isExternalOpened;
  },
};

const actions = {};

const getters: GetterTree<FlomniState, MainState> = {
  displayChat({ displayChat }) {
    return displayChat;
  },
  state({ state }) {
    return state;
  },
  inline({ inline }) {
    return inline;
  },
  isYaMapsBalloonOpened({ isYaMapsBalloonOpened }) {
    return isYaMapsBalloonOpened;
  },
};

export const flomniModule: Module<FlomniState, MainState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
