import getLocaleKeys from '#root/src/utils/getLocaleKeys';
import { WebsiteId } from '#root/src/env';
import { PageContextClient, PageContextServer } from './types';

// /ru, /ru/, /ru/test - не нужен редирект
// /test, /rutest, / - нужен
const testLocalePrefix = (str: string, locale: string) =>
  new RegExp(`^/${locale}/(.)*|^/${locale}$`).test(str);

const getCurrentLocale = (localeKeys: WebsiteId[], locale: WebsiteId) => {
  if (localeKeys.includes(locale)) {
    return locale;
  }

  return localeKeys[0] || 'ru';
};

export const getRedirectPageContextData = (
  pageContext: PageContextClient | PageContextServer,
  locale?: string,
) => {
  const { urlOriginal, redirectTo } = pageContext;

  const localeKeys = getLocaleKeys();

  let currentLocale = getCurrentLocale(localeKeys, locale as WebsiteId);

  let updatedRedirectTo = redirectTo;

  if (!redirectTo) {
    const needRedirect = urlOriginal && !localeKeys.some((el) => testLocalePrefix(urlOriginal, el));

    if (!needRedirect) {
      currentLocale = localeKeys.find((el) => testLocalePrefix(urlOriginal, el)) || 'ru';
    }

    updatedRedirectTo = needRedirect ? `/${currentLocale}${urlOriginal}` : '';
  }

  return {
    redirectTo: updatedRedirectTo,
    currentLocale,
  };
};
