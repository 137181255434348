import { ActiveHeadEntry, Head, Unhead } from '@unhead/schema';
import IPage from '#root/src/types/dto/page';
import { getDefaultMetaData } from '#root/src/constants/default-meta-data';
import { VueI18nTranslation } from 'vue-i18n';
import cloneDeep from 'lodash/cloneDeep';

interface MetaAsObj extends Head {
  index: number;
}

export function prepareAdminPageMeta({ title, metaTags, scripts }: IPage) {
  const result: Head = {};

  if (title) {
    result.title = title;
  }

  if (metaTags && metaTags.items && Array.isArray(metaTags.items)) {
    result.meta = metaTags.items.map(({ name, value }) => {
      return {
        name,
        content: value,
      };
    });
  }

  if (metaTags && !metaTags.items && Object.keys(metaTags).length > 0) {
    result.meta = Object.keys(metaTags).map((name) => {
      return {
        name,
        content: (metaTags as Record<string, string>)[name],
      };
    });
  }

  if (scripts && scripts.length && Array.isArray(scripts)) {
    result.script = scripts.map(({ type, text }) => {
      return {
        type,
        innerHTML: JSON.parse(text),
        hid: 'ldjson',
      };
    });
  }

  return result;
}

export function prepareAllMeta(newMetaData: Head, defaultMeta: Head) {
  try {
    const result = cloneDeep(defaultMeta);

    const { title: newTitle, meta: newMeta } = cloneDeep(newMetaData);

    if (newTitle) {
      result.title = newTitle;
    }

    const defaultMetaAsObj = result?.meta?.reduce((obj: Record<string, MetaAsObj>, curr, i) => {
      const name = curr?.name;
      if (name) {
        obj[name as string] = { ...curr, index: i };
      }

      return obj;
    }, {});

    if (newMeta && defaultMetaAsObj) {
      newMeta.forEach((el) => {
        const newEl = { ...el, 'data-n-head': 'ssr' };

        if ((newEl.name as string).startsWith('og:')) {
          newEl.property = newEl.name;
          delete newEl.name;
        }

        const name = el.name as string;

        if (name && defaultMetaAsObj[name] && result.meta) {
          result.meta[defaultMetaAsObj[name].index] = newEl;
          return;
        }

        result?.meta?.push(newEl);
      });
    }
    return result;
  } catch (e) {
    return defaultMeta;
  }
}
export function updateHead(
  t: VueI18nTranslation,
  activeHead?: Unhead,
  activeEntry?: ActiveHeadEntry<Head>,
  metaData?: Head,
): ActiveHeadEntry<Head> | undefined {
  if (!activeHead) {
    return;
  }

  if (activeEntry) {
    activeEntry.dispose();
  }

  let newMetaData: Head = getDefaultMetaData(t);

  if (metaData) {
    newMetaData = prepareAllMeta(metaData, newMetaData) as Head;
  }

  return activeHead.push(newMetaData);
}

export function rewriteServerMetaData(
  t: VueI18nTranslation,
  activeHead?: Unhead,
  activeEntry?: ActiveHeadEntry<Head>,
  metaData?: Head,
) {
  const defaultMeta = document.querySelectorAll('[data-n-head="ssr"]');
  const arrMeta = [...defaultMeta];
  arrMeta.forEach((n) => n.remove());

  return updateHead(t, activeHead, activeEntry, metaData);
}
