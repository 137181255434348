import { Multitone, getInstanceFactory } from '@cdek-ui-kit/vue';

import axios from 'axios';
import https from 'https';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getEnvUniversal } from '#root/src/utils/env-utils';

const EXPRESS_URL = getEnvUniversal('VITE_DEV_EXPRESS_ENDPOINT', 'VITE_HTTP_ENDPOINT');

export class ExpressApi extends Multitone {
  private _axios: AxiosInstance;

  constructor() {
    super();

    const config: AxiosRequestConfig = {
      baseURL: EXPRESS_URL,
    };

    if (typeof window === 'undefined') {
      config.httpsAgent = new https.Agent({
        rejectUnauthorized: false,
      });
    }

    this._axios = axios.create(config);
  }

  get(url: string) {
    return this._axios.get(url);
  }
}

export const getExpressApi = getInstanceFactory<ExpressApi>(ExpressApi);
