export default function initMindbox(enabled = false) {
  if ('mindbox' in window || !enabled) {
    return;
  }

  const script = document.createElement('script') as HTMLScriptElement;
  script.text = `
          mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
          mindbox.queue = mindbox.queue || []; mindbox('create', {
          endpointId: 'cdek-website-loyalty' });
      `;

  const head = document.getElementsByTagName('head')[0] as HTMLHeadElement;
  head.appendChild(script);

  const mindboxSript = document.createElement('script') as HTMLScriptElement;
  mindboxSript.setAttribute('src', 'https://api.mindbox.ru/scripts/v1/tracker.js');
  mindboxSript.async = true;

  head.appendChild(mindboxSript);
}
