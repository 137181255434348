import mutations from './order-create/mutations';
import actions from './order-create/actions';
import getters from './order-create/getters';
import state from './order-create/state';

const OrderCreate = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default OrderCreate;
