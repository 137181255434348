export const state = () => ({
  consentToUseOfCookies: true,
});

export const mutations = {
  onUpdateState(store, payload) {
    store.consentToUseOfCookies = payload.consentToUseOfCookies;
  },
};

export const actions = {
  init({ commit }, cookiesVal = false) {
    commit('onUpdateState', {
      consentToUseOfCookies: cookiesVal,
    });
  },
  agreeToUseOfCookies({ commit }) {
    commit('onUpdateState', {
      consentToUseOfCookies: true,
    });
  },
};

export const getters = {
  consentToUseOfCookies({ consentToUseOfCookies }) {
    return consentToUseOfCookies;
  },
};

const cookieAlertModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default cookieAlertModule;
