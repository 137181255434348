import { DirectiveBinding } from 'vue';

const testIdDirective = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    const environment =
      import.meta.env.VITE_ENVIRONMENT || (import.meta.env.DEV ? 'development' : 'production');

    if (environment !== 'production') {
      el.setAttribute('data-test', binding.value);
    }
  },
};

export default testIdDirective;
