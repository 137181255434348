import type { App } from 'vue';
import RestApiClient from '#root/src/api-clients/rest-api-client';

export default {
  install: (app: App, runsOnClient = false, url = import.meta.env.VITE_HTTP_ENDPOINT) => {
    let client: RestApiClient;

    if (!runsOnClient) {
      if (global.cdekRestApiClient) {
        client = global.cdekRestApiClient;
      } else {
        client = new RestApiClient(url);
        global.cdekRestApiClient = client;
      }
    } else {
      client = new RestApiClient(url);
    }

    // У нас большая часть компонентов будет на options api и там где есть аксиос
    // мы будем использовать его как  this.$restApi.
    app.config.globalProperties.$restApi = client;

    // Что-то новое будем писать и использованием composition api
    // при таком подходе мы не имеем доступа к this.
    // Чтобы использовать там клиент, будем делать inject('restApi')
    app.provide('restApi', client);
  },
};
