import type { App } from 'vue';
import PmsApiClient from '@/api-clients/pms-api-client';

export default {
  install: (app: App, host = import.meta.env.VITE_HTTP_ENDPOINT || '') => {
    const client = new PmsApiClient(host);
    app.config.globalProperties.$pmsApi = client;
    app.provide('pmsApi', client);
  },
};
