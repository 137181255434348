import { cdekConsoleLog } from '@/utils/console-wrapper';
import { DirectiveBinding, ComponentPublicInstance } from 'vue';

type CalculatePositionCoords = { width: string; top: string; left: string };
interface AppendToBodyHTMLElement extends HTMLElement {
  unbindPosition: unknown;
}

type AppendToBodyInstance = ComponentPublicInstance<{
  appendToBody: unknown;
  calculatePosition: (el: unknown, context: unknown, coords: CalculatePositionCoords) => unknown;
}>;

export const appendToBodyDirective = {
  mounted(el: AppendToBodyHTMLElement, bindings: DirectiveBinding) {
    const context = bindings.instance as AppendToBodyInstance;

    if (context && context.appendToBody) {
      let config = {
        ref: context.$refs.toggle || null,
        active: true,
      };

      if (bindings.value) {
        config = Object.assign(config, bindings.value);
      }

      if (!config.active) return;
      const element = config.ref;

      if (element) {
        const { height, top, left } = (element as HTMLElement).getBoundingClientRect();

        el.unbindPosition = context.calculatePosition(el, context, {
          width: 'auto',
          top: `${window.scrollY + top + height}px`,
          left: `${window.scrollX + left}px`,
        });

        document.body.appendChild(el);
      } else {
        cdekConsoleLog('The element from which you need to make calculations is not installed');
      }
    }
  },

  unmounted(el: AppendToBodyHTMLElement, bindings: DirectiveBinding) {
    const context = bindings.instance as AppendToBodyInstance;

    if (context?.appendToBody) {
      if (el.unbindPosition && typeof el.unbindPosition === 'function') {
        el.unbindPosition();
      }
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  },
};
