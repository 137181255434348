import { DELIVERY_TYPES } from '@/components/NewOrder/constants';
import {
  getTariff,
  isFirstTab,
  isThirdTab,
  displayError,
  findTariffOtherModes,
  findCourierTariffWithTab,
} from '@/components/NewOrder/utils';
import { clientAxiosErrorsHandler } from '@/utils/handleErrors/handleErrors';

export default {
  resetFull({ commit }) {
    commit('setDefaultState');
  },
  async loadSizeOptions({ commit }) {
    const result = await this.$apiClient.cabinet.getPackagingTypes();
    commit('setSizeOptions', result.data);
  },
  async updateCurrencies({ commit, state, getters, rootGetters }) {
    if (!state.direction.from) return;

    try {
      const currencies = await this.$apiClient.cabinet.getCurrenciesByCityCode(
        state.direction.from.id,
      );

      const defaultCurrency = currencies.find(
        // для не рф сайта устанавливаем дефолтную валюту данного сайта не смотря на валюту города отправителя.
        // для каждого города своя валюта (привязка к стране).
        // чтобы дать возможность пользователю сделать расчет в валюте его страны вне зависимоти от выбранных направлений.
        (currency) =>
          rootGetters.isRuSite
            ? currency.default
            : currency.shortName === getters.websiteDefaultCurrency.shortName,
      );

      commit('setCurrencies', currencies);
      commit('setActiveCurrency', defaultCurrency);
    } catch (e) {
      clientAxiosErrorsHandler({ error: e, place: 'NewOrder' });
    }
  },
  async estimate(
    { dispatch, commit, state, getters },
    { isUpdateTariff = false, withoutInsurance = false } = {},
  ) {
    commit('setIsRequestDirection', true);
    commit('setIsDirectionError', false);

    let senderCityId = state.direction.from.uuid;
    let receiverCityId = state.direction.to.uuid;

    try {
      if (!senderCityId) {
        const senderCity = await this.$apiClient.cabinet.getCityById(state.direction.from.id);
        commit('setFrom', { ...state.direction.from, uuid: senderCity.uuid });
        senderCityId = senderCity.uuid;
      }

      if (!receiverCityId) {
        const receiverCity = await this.$apiClient.cabinet.getCityById(state.direction.to.id);
        commit('setTo', { ...state.direction.to, uuid: receiverCity.uuid });
        receiverCityId = receiverCity.uuid;
      }
    } catch (e) {
      clientAxiosErrorsHandler({ error: e, place: 'NewOrder' });
      displayError(this, e);
      commit('setIsRequestDirection', false);
      return;
    }

    const size = state.direction.size;

    const form = {
      payerType: state.tabForm.payer,
      currencyMark: getters.currency.shortName,
      senderCityId,
      receiverCityId,
      packages: [
        {
          height: size.dimension.height,
          length: size.dimension.length,
          width: size.dimension.width,
          weight: size.maxWeight,
        },
      ],
    };
    try {
      const tabs = await this.$apiClient.cabinet.getOrderTariffV2(form);

      if (tabs && tabs.length > 0) {
        commit('setTabs', tabs);
        if (tabs.length && !isUpdateTariff) {
          // По умолчанию таб выбран первый
          commit('setCurrentTab', tabs[0]);
        }

        if (isUpdateTariff) {
          await dispatch('reInitTariff', { withoutInsurance });
        } else {
          await dispatch('initTariff');
        }
      } else {
        commit('setIsDirectionError', true);
      }
    } catch (e) {
      clientAxiosErrorsHandler({ error: e, place: 'NewOrder' });
      displayError(this, e);
    } finally {
      commit('setIsRequestDirection', false);
    }
  },
  async initTariff({ dispatch }) {
    await dispatch('setDefaultTariff');
    await dispatch('loadTariffInfo');
  },
  reInitTariff({ dispatch }, { withoutInsurance = false } = {}) {
    dispatch('loadTariffInfo', { withoutInsurance });
  },
  async loadTariffInfo(
    { state, getters, commit, dispatch, rootGetters },
    { withUpdateTariff = true } = {},
  ) {
    if (withUpdateTariff) {
      await dispatch('updateTariff');
    }

    if (!state.currentTariff) {
      return;
    }

    commit('setErrorAlerts', []);
    commit('setIsRequestTariff', true);
    const size = state.direction.size;

    const form = {
      withoutAdditionalServices: 0,
      serviceId: state.currentTariff.serviceId,
      mode: state.currentTariff.mode,
      payerType: state.tabForm.payer,
      currencyMark: getters.currency.shortName,
      senderCityId: state.direction.from.uuid,
      receiverCityId: state.direction.to.uuid,
      packages: [
        {
          height: size.dimension.height,
          length: size.dimension.length,
          width: size.dimension.width,
          weight: size.maxWeight,
        },
      ],
      additionalServices: [],
    };

    try {
      const tariffInfo = await this.$apiClient.cabinet.getTariffInfo(
        rootGetters.isLoggedInCabinet,
        form,
      );

      commit('setCost', {
        totalCost: tariffInfo.totalCost,
        deliveryPrice: tariffInfo.deliveryPrice,
      });
      commit('setLoyalty', tariffInfo.loyalty);
      commit('setIsLoadTariffInfoError', false);
    } catch (e) {
      commit('setIsLoadTariffInfoError', true);

      let isShowDisplayError = true;
      if (e.response?.data?.alerts) {
        commit('setErrorAlerts', e.response.data.alerts);

        // Скрываем показ уведомления для ОС, так как оно выводится рядом с полем ввода
        isShowDisplayError = !e.response.data.alerts.some((item) =>
          item.code?.includes('insurance'),
        );
      }

      if (isShowDisplayError) {
        displayError(this, e);
      }

      clientAxiosErrorsHandler({ error: e, place: 'NewOrder' });
    } finally {
      commit('setIsRequestTariff', false);
    }
  },
  setDefaultTariff({ commit, state }) {
    const { currentTab, senderOffice, receiverOffice, tariffProfiEnabled } = state;
    if (!currentTab) {
      return;
    }

    const tabForm = { ...state.tabForm };
    let tariff = null;

    if (isFirstTab(currentTab)) {
      tabForm.recipientType = DELIVERY_TYPES.OFFICE;
      tabForm.senderType = DELIVERY_TYPES.OFFICE;
      tabForm.optionValue = null;

      tariff = getTariff({
        tariffs: currentTab.tariffs,
        form: tabForm,
        senderOffice,
        receiverOffice,
        tariffProfiEnabled,
      });
      if (!tariff) {
        // Ищем любой подходящий
        const result = findTariffOtherModes(
          currentTab.tariffs,
          tabForm,
          senderOffice,
          receiverOffice,
        );
        tariff = result.currentTariff;
        tabForm.senderType = result.senderType;
        tabForm.recipientType = result.recipientType;
      }
    } else if (isThirdTab(currentTab)) {
      tariff = getTariff({
        tariffs: currentTab.tariffs,
        form: tabForm,
        senderOffice,
        receiverOffice,
        tariffProfiEnabled,
      });

      if (!tariff) {
        // Ищем любой подходящий
        const result = findTariffOtherModes(
          currentTab.tariffs,
          tabForm,
          senderOffice,
          receiverOffice,
        );
        tariff = result.currentTariff;
        tabForm.senderType = result.senderType;
        tabForm.recipientType = result.recipientType;
      }

      tabForm.optionValue = tariff?.ec4Id;
    } else {
      tabForm.optionValue = null;
      tariff = getTariff({
        tariffs: currentTab.tariffs,
        form: tabForm,
        senderOffice,
        receiverOffice,
        tariffProfiEnabled,
      });
      if (!tariff && tabForm.senderType === 'OFFICE' && tabForm.recipientType === 'OFFICE') {
        // Ищем любой подходящий
        const result = findTariffOtherModes(
          currentTab.tariffs,
          tabForm,
          senderOffice,
          receiverOffice,
        );
        tariff = result.currentTariff;
        tabForm.senderType = result.senderType;
        tabForm.recipientType = result.recipientType;
      }
    }

    commit('setCurrentTariff', tariff);
    commit('setTabForm', tabForm);
  },
  updateTariff({ state, commit, getters, dispatch }) {
    const { currentTab, senderOffice, receiverOffice, preferredTariff } = state;

    if (!currentTab) {
      return;
    }

    if (!isFirstTab(currentTab)) {
      if (isThirdTab(currentTab) && state.tabForm.optionValue) {
        const currentTariff = currentTab.tariffs.find(
          (tariff) => tariff.ec4Id === state.tabForm.optionValue,
        );
        commit('setCurrentTariff', currentTariff);
      } else {
        const tariff = getTariff({
          tariffs: currentTab.tariffs,
          form: state.tabForm,
          senderOffice,
          receiverOffice,
          preferredTariff,
        });
        commit('setCurrentTariff', tariff);

        if (isThirdTab(currentTab)) {
          dispatch('updateTabForm', { optionValue: tariff.ec4Id });
        }
      }

      return;
    }

    const tabForm = { ...state.tabForm };

    if (isFirstTab(currentTab)) {
      // Важно
      // Для первого таба, курьерская доставка является условной
      // Это значит, что выглядит как OFFICE-OFFICE, но появляются доп услуги:
      // 1) Забор в городе отправителя
      // 2) Доставка в городе получателя
      // 3) Чистим офисы иначе столкнемся с проблемой постоматов
      if (tabForm.recipientType === DELIVERY_TYPES.HOME && getters.isDeliveryOfCargo) {
        tabForm.recipientType = DELIVERY_TYPES.OFFICE;
      }

      if (tabForm.senderType === DELIVERY_TYPES.HOME && getters.isPickingUpCargo) {
        tabForm.senderType = DELIVERY_TYPES.OFFICE;
      }
    }

    if (getters.isTariffProfiAvailable) {
      commit(
        'setCurrentTariff',
        getTariff({
          tariffs: currentTab.tariffs,
          form: tabForm,
          senderOffice,
          receiverOffice,
          tariffProfiEnabled: state.tariffProfiEnabled,
        }),
      );
    } else {
      commit(
        'setCurrentTariff',
        getTariff({
          tariffs: currentTab.tariffs,
          form: tabForm,
          senderOffice,
          receiverOffice,
          preferredTariff,
        }),
      );
    }
  },
  async initCourierTariff({ commit, dispatch, state }) {
    if (!state.tabs || !state.tabs.length) {
      return;
    }

    const result = findCourierTariffWithTab(state.tabs);

    commit('updateIsTabClicked', true);

    if (!result) {
      // Когда не нашли нужный тариф, просто выбираем второй таб
      const secondTab = state.tabs.find((item) => item.placing === 2);
      if (secondTab) {
        dispatch('initTab', secondTab);
      }

      return;
    }

    // Обновляем данные для найденного тарифа
    commit('setCurrentTab', result.tab);
    commit('setCurrentTariff', result.tariff);

    const form = { senderType: result.senderType, recipientType: result.recipientType };
    if (isThirdTab(result.tab)) {
      form.optionValue = result.tariff.ec4Id;
    }

    dispatch('updateTabForm', form);
    await dispatch('loadTariffInfo');
  },
};
