import { getCabinetUrl } from '@/utils/getCabinetUrl';
import { getRedirectPageContextData } from './getRedirectTo';
import { createPageApp } from './app';
import type { PageContextClient } from './types';
// Эта функция отвечает за то как будет рендериться наша страница на клиенте.
// Для каждой странице можно написать свой рендерер в зависимости от потребностей.
// На текущем этапе мне нужно чтобы приложение работало в режиме SSR.
// То есть, чтобы html мы получали только при первом запросе, и вся остальная навигация
// происходила на клиенте

// https://vite-plugin-ssr.com/server-routing-vs-client-routing
// Если тру, роутинг происходит на клиенте. Клиент не запрашивает каждый раз новый html.
// Если фолз, на каждый переход внутри сайта запрашивается новый html
export const clientRouting = true;
// https://vite-plugin-ssr.com/clientRouting#link-prefetching
// Как мы хотим подгружать ресурсы из отрисованых ссылок
// { when: HOVER } - при наведении мышки
// { when: VIEWPORT } - при попадании ссылки во вьюпорте
// false - не хотим
// export const prefetchStaticAssets = { when: 'HOVER' };
export const prefetchStaticAssets = false;

let app: Awaited<ReturnType<typeof createPageApp>>;

// pageContext - объект плагина, в котором хранятся все данные о текущей странице.
async function render(pageContext: PageContextClient) {
  if (!app) {
    // Если внутри html который пришел с бека нет контента, считаем что это клинет онли страница
    app = await createPageApp(
      pageContext,
      document.getElementById('page')?.innerHTML === '',
      pageContext.initialSiteData,
      true,
    );
    app.mount('#app');
  } else {
    const store = app.config.globalProperties?.$store;

    const locale = store?.getters?.currentLocale;

    const isLoggedInCabinet = store?.getters?.isLoggedInCabinet;

    const { redirectTo } = getRedirectPageContextData(pageContext, locale);

    const {
      urlParsed: { pathname, searchOriginal },
    } = pageContext;

    const cabinetUrl = isLoggedInCabinet
      ? getCabinetUrl(pathname, searchOriginal ?? '', locale)
      : '';

    if (redirectTo) {
      app.config.globalProperties.$router.push(redirectTo);
      return;
    }

    if (cabinetUrl) {
      window.location.href = cabinetUrl;
      return;
    }

    app.changePage(pageContext);
  }
}
// Вызывается при первом клике по ссылке
function onPageTransitionStart() {
  app && app.startLoading();
}

// Вызывается когда все данные пришли и надо перерисовать страницу
function onPageTransitionEnd() {
  app && app.stopLoading();
}

export { render };
export { onPageTransitionStart };
export { onPageTransitionEnd };
